<template>
  <div class="personal">
      <div class="personal-center">
          <div class="personal-left">
              <div class="personal-portrait">
                  <img src="../assets/img/personal-bg.png" alt="">
                  <p>{{companyInfo.name}}</p>
                  <span style="margin-top:10px;" v-if="companyInfo.status == 0">{{companyInfo.account}}</span>
                  <button type="button" v-if="companyInfo.status == 2">审核中</button>
                  <button type="button" v-if="companyInfo.status == 3">驳回</button>
              </div>
              <div class="personal-tabs">
                    <div class="personal-li-tab" v-for="(item,index) in tabsParam" @click="toggleTabs(item.pathUrl,index)"
                        :class="{active:index == nowIndex}" :key="index">
                        <img :src="index == nowIndex ? item.path: item.img" alt="">
                        <p>{{item.text}}</p>
                        <i v-if="item.text == '消息' && companyNumberMessage.messageNum !== 0"></i>
                    </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { getNumberByCompany,getCompanyInfo } from "../api/personal"
export default {
    components:{
        
    },
    data(){
        return{
            tabsParam: [{
                img:require('../assets/img/information.png'),
                path:require('../assets/img/information-active.png'),
                text:'预订信息',
                pathUrl:'/schedule'
            },{
                img:require('../assets/img/essential.png'),
                path:require('../assets/img/essential-active.png'),
                text:'基本信息',
                pathUrl:'/essential'
            },{
                img:require('../assets/img/message.png'),
                path:require('../assets/img/message-active.png'),
                text:'消息',
                pathUrl:'/message'
            },{
                img:require('../assets/img/set.png'),
                path:require('../assets/img/set-active.png'),
                text:'安全设置',
                pathUrl:'/security'
            }],
            nowIndex: 0,
            name:"",
            companyNumberMessage:"",
            account:"",
            status:"",
            companyInfo:{}
        }
    },
    created(){
        this.name = JSON.parse(localStorage.getItem('name'))
        this.account = JSON.parse(localStorage.getItem('account'))
        this.status = localStorage.getItem('status')
        this.getNumberByCompany()

        // this.nowIndex = this.$store.state.activeIndex
        console.log(this.$store.state.nowIndex)
        this.nowIndex = this.$store.state.nowIndex

        this.getCompanyInfo()
    },
    computed: {
      activeIndex() {
        return this.$store.state.nowIndex;
      }
    },
    watch:{
      activeIndex:function(old,newd){
          console.log(old)
          this.nowIndex = old;
          this.toggleTabs(this.$store.state.pathUrl,this.$store.state.nowIndex)
        }
    },
    methods:{
        toggleTabs(pathUrl,index) {
            console.log(index)
            this.nowIndex = index;
            this.$router.push(pathUrl)
                this.getNumberByCompany()
        },

        getNumberByCompany(){
            getNumberByCompany().then((res) => {
                if(res.data.code == 20000){
                    this.companyNumberMessage = res.data.data.companyNumberMessage
                }
            })
        },

        getCompanyInfo(){
            getCompanyInfo().then((res) => {
                if(res.data.code == 20000){
                    this.companyInfo = res.data.data.companyInfo
                }else{
                    this.$message.error(res.data.message)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.personal{
    background-color:#F8F8F8;
    .personal-center{
        .personal-left{
            width:260px;
            .personal-portrait{
                width:100%;
                height:197px;
                background-color:#FFFFFF;
                display:flex;
                align-items:center;
                justify-content: center;
                flex-direction: column;
                img{
                    width:80px;
                    height:80px;
                    border-radius:50%;
                }
                p{
                    color:#333333;
                    font-size:16px;
                    font-weight:bold;
                    margin-top:15px;
                }
                button{
                    width: 85px;
                    height: 30px;
                    // border: 1px solid #FF6100 !important;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FF6100;
                    font-size: 14px;
                    border-radius: 50px;
                    margin-top: 10px;
                    background-color: #ffffff;
                }
            }
            .personal-tabs{
                width:100%;
                background-color:#FFFFFF;
                margin-top:10px;
                .personal-li-tab{
                    height:52px;
                    border-bottom:1px solid #EEEEEE;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
                    img{
                        width:20px;
                        height: 20px;
                    }
                    p{
                        color:#333333;
                        font-size:14px;
                        margin-left:8px;
                        width:60px;
                    }
                    &:last-child{
                        border:none;
                    }
                    i{
                        width: 8px;
                        height: 8px;
                        background-color: #FF7100;
                        border: 1px solid #ffffff;
                        border-radius: 50%;
                        position: absolute;
                        left: 38%;
                        top: 23%;
                    }
                }
                .active{
                    p{
                        color:#123E90;
                        font-size:14px;
                    }
                }
            }
        }
        .personal-right{
            width:920px;
            margin-left:20px;
        }
    }
}
</style>