<template>
  <div class="home">
    <div class="home-center">
      <el-carousel :interval="4000" type="card" height="410px" arrow="always">
          <el-carousel-item v-for="(item,index) in venueAreaList" :key="index">
            <div class="home-list" @click="goVenue(index)">
              <img :src="item.image" alt="" class="home-img" />
              <div class="img-address">
                <p>【{{item.name}}】</p>
                <span>{{item.ename}}</span>
                <div class="home_bottom">
                  <p>可预订场馆</p>
                  <span>({{item.count}})</span>
                </div>
              </div>
            </div>
          </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-bottom">华熙国际投资集团保留法律许可范围内所有解释权　版权所有©华熙国际投资集团有限公司</div>
  </div>
</template>

<script>
import { listVenueArea } from "../api/home"
export default {
  name: "Home",
  components: {},
  data(){
    return{
      venueAreaList:[],
      id:"",
      index:""
    }
  },
  created(){
    this.listVenueArea()
  },
  methods:{
    //列表
    listVenueArea(){
      listVenueArea({
        type:0
      }).then((res) => {
        this.venueAreaList = res.data.data.venueAreaList
      })
    },

    goVenue(index){
      this.id = this.venueAreaList[index].id
      // console.log('首页列表',this.id)
      this.index = index
      // this.$router.push({
      //   name: "venue", 
      //   params: {
      //     id:this.id,
      //     index:this.index
      //   }
      // });
      this.$router.push({
        path: '/venue',
        query: {
          id:this.id,
          index:this.index
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height:94%;
  background-image: url('../assets/img/home-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display:flex;
  align-items: center;
  justify-content: center;
  position:fixed;
  left:0;
  .home-center {
    width: 1200px;
    height: 420px;
    margin: auto;
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }
    .el-carousel__item:nth-child(2n) {
      // background-color: #99a9bf;
      // background: rgba(255, 255, 255, 0.2);
      border-radius: 12px;
      border: 1px solid #F8C400;
    }
    .el-carousel__item:nth-child(2n + 1) {
      // background-color: #d3dce6;
      border: 1px solid #F8C400;
      // background: rgba(255, 255, 255, 0.2);
      border-radius: 12px;
    }
    /deep/ .el-carousel__arrow {
      display: block;
      width: 80px;
      height: 80px;
    }
    /deep/ .el-carousel__item--card{
      width:25%;
    }
    /deep/ .el-carousel__item, .el-carousel__mask{
      left:144px;
    }
    /deep/ .el-carousel__mask{
      opacity: 0;
      background-color:rgba(255,255,255,1);
    }
    /deep/ .el-carousel__arrow i {
      font-size: 26px;
    }
    /deep/ .el-carousel__indicators--outside{
      display: none;
    }
    .home-list{
      padding:30px 0;
      box-sizing: border-box;
      background: rgba(192, 192, 192, 0.5);
       .home-img {
        width: 240px;
        height: 240px;
        border-radius: 50%;
        background: #ffffff;
        // opacity: 0;
        margin: 0 auto;
        text-align: center;
        display: flex;
        z-index:9;
      }
      .img-address {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top:20px;
        line-height:30px;
        p {
          color: #ffffff;
          font-size: 36px;
          font-weight: bold;
        }
        span {
          font-size: 16px;
          font-weight: bold;
          color: #f8c400;
        }
        .home_bottom{
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          align-items: center;
          p{
            font-size:16px;
            color:#ffffff;
            font-weight:bold;
          }
        }
      }
    }
  }
  .home-bottom{
    width:100%;
    height:50px;
    border-top:1px solid #FFFFFF;
    display:flex;
    align-items:center;
    justify-content: center;
    color:#FFFFFF;
    font-size:14px;
    position: absolute;
    bottom:0;
    left:0;
  }
}
</style>
