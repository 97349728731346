import ajax from "./ajax"

// 首页
export function listVenueArea(params){
    return ajax({
        url:'/api/venue-area/listVenueArea',
        method:'get',
        params
    })
}

//搜索
export function getVenueInfoByQuery(params){
    return ajax({
        url:'/api/venue-info/getVenueInfoByQuery',
        method:'get',
        params
    })
}