import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Authorization :'',
    mapList:[],
    // activeList:{
      pathUrl:"",
      nowIndex:0
    // },
  },
  mutations: {
    set_token(state, Authorization ) {
      state.Authorization  = Authorization 
      localStorage.Authorization  = Authorization 
    },
    del_token(state) {
      state.Authorization  = ''
      localStorage.removeItem('Authorization')
    },
    setmapList(state,value){
      state.mapList = value
    },
    setpathUrl(state,value){
      // console.log(value);
      state.pathUrl = JSON.parse(JSON.stringify(value))
      // console.log(state.activeList);
    },
    setnowIndex(state,value){
      // console.log(value);
      state.nowIndex = JSON.parse(JSON.stringify(value))
      // console.log(state.activeList);
    }
  },
  actions: {
  },
  modules: {
  },
  //持久化
  // plugins: [createPersistedState({
  //   // 存储到sessionStorage中
  //   storage: window.sessionStorage
  // })]
})
