<template>
  <div id="app">
    <HeaderTop v-if="!$route.meta.headerTop"></HeaderTop>
    <router-view v-if="$route.meta.personal !== true" />

    <div class="content-box">
      <CatalogueLeft v-if="$route.meta.personal"></CatalogueLeft>
      <Content v-if="$route.meta.personal"></Content>
    </div>

    <!-- layout -->
    <Footer v-if="!$route.meta.footer"></Footer>

  </div>
</template>

<script>
import HeaderTop from "./components/HeaderTop";
import Footer from "./components/Footer";
import CatalogueLeft from "./layout/catalogueLeft";
import Content from "./layout/content"
export default {
    name: "Home",
    components: {
        HeaderTop,
        Footer,
        CatalogueLeft,
        Content
    },
};
</script>

<style>
img{
  /* width:747px; */
  height:auto;
  border-radius:8px;
}

.content-box{
  width:1200px;
  margin:auto;
  display:flex;
  justify-content: space-between;
  padding:20px 0;
  box-sizing: border-box;
  background-color:#F8F8F8;
}

#app{
  /* max-width:1920px; */
  background-color:#F8F8F8;
}

	html, body, div, span, object, iframe,

  h1, h2, h3, h4, h5, h6, p, blockquote, pre,

  abbr, address, cite, code,

  del, dfn, em, img, ins, kbd, q, samp,

  small, strong, sub, sup, var,

  b, i,

  dl, dt, dd, ol, ul, li,

  fieldset, form, label, legend,

  table, caption, tbody, tfoot, thead, tr, th, td,

  article, aside, canvas, details, figcaption, figure,

  footer, header, hgroup, menu, nav, section, summary,

  time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
    font-family: arial, "Hiragino Sans GB", "Microsoft Yahei", 微软雅黑, 宋体, Tahoma, Arial, Helvetica, STHeiti, serif;
    font-style:normal;
    font-weight:normal;
  }

  ul, li {
    list-style: none; 
  }


  img, div, body, html, ul, li, a {
    -webkit-touch-callout: none;
  }

  input, button {
    outline: 0; 
  }

  button {
    cursor: pointer; 
  }

  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color:#ccc; 
  }
  input{
    -webkit-appearance:none;
    border:0;
  }

  /* 登陆 */
  .login-center{
    width: 100%;
    height: 100%;
    background-image: url(/img/login-bg.9db405de.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    /* top: 0; */
    left: 0;
  }

  .login-form{
    width: 420px;
    height: 380px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .login_button{
    background-color:#123E90 !important;
    border-radius:4px !important;
    color:#FFFFFF !important;
    font-size:16px !important;
    display:flex !important;
    align-items:center;
    justify-content: center;
    margin-top:28px !important;
  }

  /* 大馆 */
  .grand-hall{
    width:52px;
    height:18px;
    background-color:#FFEBDB;
    border-radius:3px;
    color:#FF7100;
    font-size:12px;
    display:flex;
    align-items:center;
    justify-content: center;
    line-height:18px;
  }
</style>
