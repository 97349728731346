<template>
    <div class="header-top">
        <div class="header-logo" @click="goHome"><img src="../assets/img/logo.jpg" alt=""></div>
        <div class="header-right">
            <el-input v-model="name" placeholder="请输入场馆名称" clearable></el-input>
            <img src="../assets/img/search.png" alt="" @click="onList" />
            <img src="../assets/img/house.png" alt="" @click="goHome" />
            <img src="../assets/img/people.png" alt="" @click="goSchedule" />
            <div class="header-right-message">
                <img src="../assets/img/news.png" alt="" class="m-r-none" @click="goMessage" />
                <!-- <i v-if="companyNumberMessage.messageNum !== 0"></i> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getNumberByCompany } from "../api/personal"
export default {
    data() {
        return {
            name: "",
            venueInfoList: [],
            companyNumberMessage:""
        };
    },
    created() {
    },
    mounted() {},
    
    methods: {
        goHome() {
            // console.log("返回首页 >>>");
            this.$router.push("/");
        },
        goSchedule() {
            // console.log("Authorization is >>> " + localStorage.Authorization);
            if (
                localStorage.Authorization == "" ||
                localStorage.Authorization == null
            ) {
                this.$router.push("/login");
            } else {
                let obj = {pathUrl:'/schedule',nowIndex:0}
                this.$store.commit('setpathUrl','/schedule')
                this.$store.commit('setnowIndex',0)

                this.$router.push("/schedule");
            }
        },

        // goMessage() {
        //     this.$router.push("/message");
        // },

        onList() {
            if (this.name) {
                this.$router.push({
                    path: "/list",
                    query: {
                        name: this.name,
                    },
                });
                // console.log(this.name);

            } else {
                this.$router.push('/list');
                // console.log(this.name);

            }
            // console.log(this.name);
        },

        onName(value) {
            // console.log(value)
            // this.name = value
            // localStorage.setItem('venueInfo',this.name);
            this.$router.push({
                path: "/list",
                query: {
                    name: this.name,
                },
            })
        },

        goMessage(){
            console.log(111)
            this.$router.push("/message")
            let obj = {pathUrl:'/message',nowIndex:2}
            console.log(obj)
            this.$store.commit('setpathUrl','/message')
            this.$store.commit('setnowIndex',2)
            getNumberByCompany().then((res) => {
                if(res.data.code == 20000){
                    this.companyNumberMessage = res.data.data.companyNumberMessage
                    // let obj = {pathUrl:'/message',nowIndex:2}
                    // this.$store.commit('setpathUrl','/message')
                    // this.$store.commit('setnowIndex',2)
                    // this.$router.push("/message")
                }else{
                    this.$router.push("/login")
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/index.scss";
.header-top {
    // max-width:1920px;
    width:100%;
    height: 60px;
    background-color: #123e90;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-logo {
        width: 102px;
        height: 32px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .header-right {
        height: 36px;
        display: flex;
        align-items: center;
        /deep/ .el-input {
            margin-right: 14px;
            width: 326px;
        }
        /deep/ .el-input__inner {
            height: 36px;
            line-height: 36px;
        }
        img {
            width: 24px;
            height: 24px;
            margin-right: 24px;
            cursor: pointer;
        }
        .m-r-none {
            margin-right: 0;
        }
        .header-right-message{
            position: relative;
            i{
                width: 8px;
                height: 8px;
                background-color: #FF7100;
                border: 1px solid #ffffff;
                border-radius: 50%;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}
</style>